import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.hide();
    }
  }

  hide() {
    const modal = bootstrap.Modal.getInstance(this.element);
    if (modal) {
      modal.hide();
    }
  }
}

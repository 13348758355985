import {Controller} from "@hotwired/stimulus"
import CircleProgress from 'js-circle-progress'
import Axios from 'axios';

// Connects to data-controller="totp"
export default class extends Controller {
  static targets = ["apps"];

  connect() {
  }

  appsTargetConnected(app) {
    app.querySelector('.js-view').addEventListener('click', () => {
      this.onViewCodeClicked(app);
      app.querySelector('.js-code')?.addEventListener('click', () => {
        navigator.clipboard.writeText(app.querySelector('.js-code').textContent.replace(" ", ""));
      })
    });
  }

  onViewCodeClicked(app) {
    Axios.get(app.dataset.totpUrl).then((response) => {
      app.querySelector('.js-view')?.classList?.add("d-none");
      app.querySelector('.js-code').classList?.remove("d-none");
      app.querySelector('.js-code').innerHTML = response.data.code;

      this.getProcessPromise(app, response.data.time).then(() => {
        app.querySelector('.js-view')?.classList?.remove("d-none");
        app.querySelector('.js-indicator')?.replaceChildren();
        app.querySelector('.js-code').classList?.add("d-none");
        app.querySelector('.js-code').innerHTML = '';
      });
    });
  }

  initializeProgressIndicator(app, initialValue) {
    const indicatorEl = app.querySelector('.js-indicator');
    if (!indicatorEl) return null;

    const circleProgress = new CircleProgress({
      max: 31,
      value: initialValue,
      textFormat: 'none',
      anticlockwise: true,
      animation: 'none'
    });

    indicatorEl.appendChild(circleProgress);

    return circleProgress;
  }

  getProcessPromise(app, intialTimeLeft) {
    const progress = this.initializeProgressIndicator(app, intialTimeLeft);

    return new Promise((resolve) => {
      let time = intialTimeLeft;
      const interval = setInterval(() => {
        time -= 1;

        if (progress) {
          progress.value = time;
          progress.animation = 'easeInOutCubic';
        }

        if (time < 0) {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }
}

import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

/**
 * Automatically register all controllers in folder
 */
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

/**
 * Custom imports from libs
 */
import NestedForm from 'stimulus-rails-nested-form'
application.register('nested-form', NestedForm)
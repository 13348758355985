import { Controller } from "@hotwired/stimulus"
import Picker from "vanilla-picker";

// Connects to data-controller="color-picker"
export default class extends Controller {
  picker = null;

  connect() {
    this.picker = new Picker();

    if (this.getPickerInputElement().value) {
      this.getPreviewElement().style.background = this.getPickerInputElement().value;
    } else {
      this.getPreviewElement().style.background = "white"
    }

    this.getPreviewElement().addEventListener("click", (e) => this.onPickerClick(e));
    this.getPickerInputElement().addEventListener("click", (e) => this.onPickerClick(e));

    this.picker.onChange = (color) => {
      this.getPreviewElement().style.background = color.rgbaString;
      this.getPickerInputElement().value = color.rgbaString;
    };

    this.picker.onClose = () => {
      this.picker.movePopup({ parent: null }, false);
    };
  }

  onPickerClick(e) {
    e.preventDefault();

    if (!(this.getPickerInputElement().disabled)) {
      this.picker.movePopup({ parent: this.getPreviewElement() }, false);
      this.picker.openHandler(e);
    }
  }

  getPickerInputElement() {
    return this.element.querySelector('input[type="text"]');
  }

  getPreviewElement() {
    return this.element.querySelector('.preview');
  }
}

import {Controller} from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

/**
 * Connects to data-controller="turbo-modal"
 * data-url="/path/to/turbo-stream"
 */
export default class extends Controller {
  renderModal(e) {
    this.modal().show();

    let anchor = this.element;

    if (anchor.tagName !== "A") {
      anchor = document.createElement("a");
      anchor.style.display = 'd-none';
      anchor.setAttribute('href', this.element.dataset.url);
      this.modalElement().appendChild(anchor);
    }

    anchor.dataset.turboStream = "true";
    anchor.dataset.turboFrame = "app_modal";
    anchor.click();
  }

  modal() {
    return bootstrap.Modal.getOrCreateInstance(this.modalElement());
  }

  modalElement() {
    return document.querySelector('#app-modal');
  }
}

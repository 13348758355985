import {Controller} from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

// Connects to data-controller="table-collapse"
export default class extends Controller {
  static targets = ["collapse"]

  collapseTargetConnected(target) {
    target.addEventListener('click', (e) => {
      e.stopPropagation();
      if (e.target.tagName === "INPUT") return;
      if (e.target.tagName === "I") this._rotateIcon(e.target);

      const rows = Array.from(document.querySelectorAll(`[data-collapse-group="${target.dataset.collapseGroup}"]`)).filter((e) => e !== target);
      if (rows.length > 0) {
        const isHidden = rows[0].classList.contains("d-none");
        rows.forEach((row) => {
          if (isHidden) {
            row.classList.remove("d-none");
          } else {
            row.classList.add("d-none");
          }
        });
      }
    });
  }

  _rotateIcon(target) {
    if (target.style.transform) {
      target.style.transform = "";
    } else {
      target.style.transform = "rotateX(180deg)";
    }
  }
}

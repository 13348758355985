import "@hotwired/turbo-rails"

import * as bootstrap from "bootstrap"
window.Bootstrap = bootstrap; // Necessary for popper.js

import "./controllers";
import './components/sidebar';
import "~/libs/trix";
import "~/libs/cocoon";
import "~/libs/tom-select";
import "~/libs/turbo";

import { Controller } from "@hotwired/stimulus"

/**
 * Controller has to be attached on <form>
 * Connects to data-controller="refresh-form"
 */
export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener('change', () => {
        this.reloadForm();
      });
    });
  }

  reloadForm() {
    const extraParam = document.createElement('input');
    extraParam.name = "refresh_form";
    extraParam.value = "true";
    extraParam.type = "hidden";
    extraParam.classList.add("d-none");

    this.element.append(extraParam);
    this.element.submit();
  }
}

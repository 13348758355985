import { Toast } from "./sweetalert.js";

const extractAttributes = (element, ...attrs) => {
  return attrs.reduce((prev, current) => ({ ...prev, [current]: element.getAttribute(current) }), {});
};

Turbo.StreamActions.toast = function () {
  const { title, type } = extractAttributes(this, "title", "type");

  Toast(title, type).then(() => {});
};


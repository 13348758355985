import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-insert"
export default class extends Controller {
    static targets = ["textarea"]
    connect() {
    }

    addText(e){
        const editor = this.textareaTarget.querySelector("trix-editor").editor;
        const oldText = editor.getDocument().toString()
        const length = oldText.length;

        editor.recordUndoEntry(editor.getDocument().toString())
        editor.setSelectedRange(length - 1);
        editor.insertLineBreak();
        editor.insertString(e.target.dataset.text);
    }
}
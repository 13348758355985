import {Controller} from "@hotwired/stimulus"
import Axios from "axios";
import { debounce } from 'radash'

// Connects to data-controller="process-time"
export default class extends Controller {
  static targets = ["total", "totals"]

  updateTotalIndicator() {
    if (!this.updateTotalIndicator.hasOwnProperty("isPending")) {
      this.updateTotalIndicator = debounce({ delay: 100 }, this.updateTotalIndicator.bind(this));
      return this.updateTotalIndicator();
    }

    this.totalTarget.innerHTML = this.totalsTargets.reduce((acc, totalElement) => {
      const checkbox = totalElement.closest('tr').querySelector('input[type="checkbox"]');
      if (checkbox.checked) {
        return (acc + parseFloat(totalElement.innerHTML));
      }
      return acc;
    }, 0.0).toFixed(2);
  }

  totalsTargetConnected() {
    this.updateTotalIndicator();
  }

  onChange(e) {
    this.updateTotalIndicator();

    if (e.target.dataset.target) {
      const process_times = document.querySelectorAll(`input[data-process-group="${e.target.dataset.target}"]`);
      this.submitFormProcessTimes(e.target, process_times)
    } else {
      this.submitFormProcessTimes(e.target, [e.target])
    }
  }

  submitFormProcessTimes(target, process_times) {
    const processTimesIds = Array.from(process_times).map((processTime) => processTime.value);
    return Axios.put(target.dataset.url,
      {project_time_ids: processTimesIds, processed: target.checked},
      {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        }
      })
      .then((response) => Turbo.renderStreamMessage(response.data))
      .catch(function (response) {
        console.error(response);
      });
  }
}

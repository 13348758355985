import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2';

// Connects to data-controller="delete"
export default class extends Controller {
  getLocale() {
    return document.body.dataset.locale || 'fr';
  }

  getMessage() {
    const info = this.element.dataset.info;
    const isRestore = this.element.dataset.restore === "true";

    if (isRestore) {
      return this.getLocale() === 'fr' ? `Voulez-vous vraiment restaurer <b>${info}</b> ?` : `Do you really want to restore <b>${info}</b> ?`
    } else {
      return this.getLocale() === 'fr' ? `Voulez-vous vraiment supprimer <b>${info}</b> ?` : `Do you really want to delete <b>${info}</b> ?`
    }
  }

  confirm(e) {
    Swal.fire({
      title: this.getLocale() === 'fr' ? 'Êtes-vous sûr ?' : 'Are you sure?',
      html:  this.getMessage(),
      showCancelButton: true,
      confirmButtonText: this.getLocale() === 'fr' ? 'Oui' : 'Yes',
      cancelButtonText: this.getLocale() === 'fr' ? 'Non' : 'No',
    }).then(result => this.handleConfirmation(this.element, result))

    e.preventDefault();
  }

  handleConfirmation(element, result) {
    if (result.value) {
      element.removeAttribute('data-action', null);
      element.removeAttribute('data-controller', null);
      element.setAttribute('data-turbo-method', 'delete');
      setTimeout(() => {
        /**
         * Delay the final click otherwise this will call this stimulus controller again.
         */
        element.click();
      }, 50);
    }
  }
}

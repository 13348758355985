import {Controller} from "@hotwired/stimulus"

import SortableController from "@stimulus-components/sortable"

import Axios from "axios";

// Connects to data-controller="sortable"
export default class extends SortableController {
    connect() {
        super.connect();
    }

    /**
     * Override original to handle pagination properly
     */
    async onUpdate(event) {
        const {item, newIndex} = event;

        const currentPage = (new URL(document.location)).searchParams.get("page") || 1;
        const perPage = (new URL(document.location)).searchParams.get("per_page") || 12;
        const newPosition = newIndex + ((currentPage - 1) * perPage) + 1;


        try {
            await this.patch(item.dataset.sortableUpdateUrl, this.positionFormData(newPosition));
        } catch {
            this.handleFailed(event);
        }
    }

    handleFailed({item, newIndex, oldIndex, from}) {
        const items = from.querySelectorAll(':scope > tr');
        from.insertBefore(item, items[oldIndex + (oldIndex > newIndex)]);
        return false
    }

    patch(url, data) {
        return Axios.put(url, data, {
                headers: {
                    'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
                }
            }
        );
    }

    positionFormData(position) {
        const data = new FormData();
        data.append(
            this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue,
            position
        );
        return data;
    }
}

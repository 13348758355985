import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["button", "text", "textRight"]

  connect() {
    this.buttonTarget.addEventListener('click', (e) => {
      navigator.clipboard.writeText(this.textTarget.textContent);
      e.stopPropagation();
    });

    this.buttonTarget.addEventListener('contextmenu', (e) => {
      navigator.clipboard.writeText(this.textRightTarget.textContent);
      e.preventDefault();
      e.stopPropagation();
      return false;
    });
  }
}

import {Controller} from "@hotwired/stimulus"
import CircleProgress from 'js-circle-progress'
import Axios from 'axios';

// Connects to data-controller="credential"
export default class extends Controller {
  static targets = ["credentials"];

  connect() {
  }

  credentialsTargetConnected(credential) {
    credential.addEventListener('click', () => {
      this.onViewPasswordClicked(credential);
    });
  }

  onViewPasswordClicked(credential) {
    Axios.get(this.element.dataset.url).then((response) => {
      navigator.clipboard.writeText(response.data[credential.dataset.dataType]);
    });
  }
}
